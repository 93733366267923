import React from 'react'
import Layout2 from "../layouts/layout2"
const Community = () => {
  return (
    <Layout2>
      Coming soon..
    </Layout2>
    );
}

export default Community